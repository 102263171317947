<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-select
          v-model="vm.mainIncomeSource"
          :items="mainIncomeSource"
          label="Main Income Source"
          v-validate="'required'"
          data-vv-as="Main income source"
          name="mainIncomeSource"
          :error-messages="errors.collect('mainIncomeSource')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.employment"
          :items="employmentStatus"
          label="Employment Status"
          v-validate="'required'"
          data-vv-as="Employment"
          name="employment"
          :error-messages="errors.collect('employment')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.recentWorkParticipation"
          :items="recentWorkParticipation"
          label="Last two years main work participation type"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="vm.literacySkills"
          :items="literacySkills"
          label="Literacy skills"
          v-validate="'required'"
          data-vv-as="Literacy skills"
          name="literacySkills"
          :error-messages="errors.collect('literacySkills')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.numeracySkills"
          :items="numeracySkills"
          label="Numeracy skills"
          v-validate="'required'"
          data-vv-as="Numeracy skills"
          name="numeracySkills"
          :error-messages="errors.collect('numeracySkills')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.highestLevelOfSchooling"
          :items="highestLevelOfSchooling"
          label="Highest level of schooling"
          v-validate="'required'"
          data-vv-as="Highest level of schooling"
          name="highestLevelOfSchooling"
          :error-messages="errors.collect('highestLevelOfSchooling')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasAnyOtherWorkRelatedQualifications"
          label="Does the participant have any other work related qualifications"
          v-validate="'required'"
          data-vv-as="Does the participant have any other work related qualifications"
          name="hasAnyOtherWorkRelatedQualifications"
          :error-messages="errors.collect('hasAnyOtherWorkRelatedQualifications')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.hasAnyOtherWorkRelatedQualifications == true">
        <v-text-field
          v-model="vm.listOtherQualifications"
          label="If Yes, please list the qualifications"
          v-validate="'required'"
          data-vv-as="List other qualifications"
          name="listOtherQualifications"
          :error-messages="errors.collect('listOtherQualifications')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" v-if="vm.hasAnyOtherWorkRelatedQualifications == true">
        <v-radio-group
          v-model="vm.isAbleToStillUseQualification"
          label="Is the participant still able to use their Qualification?"
          v-validate="'required'"
          data-vv-as="Is the participant still able to use their qualification"
          name="isAbleToStillUseQualification"
          :error-messages="errors.collect('isAbleToStillUseQualification')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>



<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "employment-training-education-form",
  data: () => ({
    vm: new Models.EmploymentTrainingEducation(),
    highestLevelOfSchooling: [
      "Currently studying",
      "Secondary - Year 10",
      "Secondary - Year 12",
      "VCAL",
      "University / Tafe"
    ],
    literacySkills: [
      "Good reading and writing skills",
      "Basic reading and writing skills",
      "Poor or no reading and writing skills"
    ],
    numeracySkills: [
      "Good numeracy skills",
      "Basic numeracy skills",
      "Poor or no numeracy skills"
    ],
    mainIncomeSource: [
      "Not Known",
      "Disability Support Pension",
      "Newstart / Youth Allowance",
      "Paid Employment",
      "Compensation Income",
      "TAC",
      "Other Income",
      "Other Pension / Benefit",
      "Nil Income"
    ],

    employmentStatus: [
      "Not in Labour Force",
      "Job Seeker",
      "Employed - Part Time",
      "Employed - Casual",
      "Employed - Full Time",
      "Not Applicable (due to age)",
      "Work Experience",
      "Supported Employment - Full Time",
      "Supported Employment - Part Time",
      "Volunteering"
    ],
    recentWorkParticipation: [
      "Paid Work",
      "Unpaid Work",
      "Not in the Workforce",
      "Not working but looking for Work",
      "Community Development Employment"
    ],

    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.employmentTrainingEducation == undefined
          ? {}
          : this.$store.getters.currentCase.employmentTrainingEducation;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.employmentTrainingEducation = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

